import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import Loader from "./layouts/Loader/Loader";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import Router from "./Router";
import "bootstrap/dist/js/bootstrap.js";
import "swiper/css";

const Root = () => {
  useEffect(() => {
    // Register the service worker
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log("Service worker registration successful:", registration);
        })
        .catch((error) => {
          console.log("Service worker registration failed:", error);
        });
    }
  }, []); // Empty dependency array to run the effect only once

  return (
    <Provider store={store}>
      <Fragment>
        <BrowserRouter>
          <React.Suspense fallback={<Loader />}>
            <Router />
          </React.Suspense>
        </BrowserRouter>
      </Fragment>
    </Provider>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<Root />, rootElement);
