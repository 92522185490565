import Axios from "axios";

const BaseURL = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const token = localStorage.getItem("token");
if (token) {
  BaseURL.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
export const setToken = (token) => {
  BaseURL.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  localStorage.setItem("token", token);
};
BaseURL.defaults.headers.post["Content-Type"] = "application/json";

export default BaseURL;
